.trending {
  padding: 4rem;
  background: #262430;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the flex container */
  color: #fff;
}

.title {
  margin-bottom: 20px;
}

.title h2 {
  font-size: 34px; /* Adjust the font size */
  font-weight: 400; /* Adjust the font weight */
}

.imageButton {
  text-decoration: none; /* Remove default underline */
  color: #fff; /* Set link text color to white */
}

.imageButton:hover {
  color: #fff; /* Maintain link text color on hover */
}

.imageButton:visited {
  color: #fff; /* Set link text color to white for visited links */
}

.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center the images horizontally */
}

.imageItem {
  text-align: center;
  margin: 0 10px; /* Add some space between images */
}

.imageItem img {
  max-width: 100%;
  height: auto;
}

.imageItem p {
  margin-top: 10px;
}

.cta button {
  background-color: gold; /* Blue color for the button */
  color: black; /* White text color */
  padding: 10px 20px; /* Padding around the text */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  font-weight: 700;
  cursor: pointer; /* Cursor on hover */
}

.cta button:hover {
  background-color: #a78f04; /* Darker blue color on hover */
}
