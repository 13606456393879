.pricing-container {
    padding: 20px;
    background-color: rgb(46, 46, 55);
    color: white;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .pricing-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .pricing-table th,
  .pricing-table td {
    border: 1px solid white;
    padding: 15px;
    text-align: left;
  }
  
  .pricing-table th {
    background-color: rgb(46, 46, 55);
    color: white;
  }
  
  .pricing-table td {
    background-color: rgb(66, 66, 77);
  }
  
  .pricing-table tr:hover {
    background-color: rgb(86, 86, 97);
  }
  
  button {
    background-color: white;
    color: rgb(46, 46, 55);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: rgb(220, 220, 220);
  }
  