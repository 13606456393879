.service-container {
    padding-top: 20px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 30px;
    background-color: rgb(46, 46, 55);
    color: white;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  h3 {
    text-align: center;
    margin-top: -15px;
    margin-bottom: 20px;
    color: white;
  }
  
  .section {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .section.left img {
    margin-right: 20px;
  }
  
  .section.right {
    flex-direction: row-reverse;
  }
  
  .section.right img {
    margin-left: 20px;
  }
  
  .section img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
  }
  
  .section p {
    flex: 1;
    margin: 0;
    line-height: 30px;
    font-size: 18px;
  }
  
  .sign-up-form {
    margin-top: 40px;
  }
  
  .sign-up-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sign-up-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px; /* Increased width of the form */
    margin: 0 auto; /* Center the form */
  }
  
  .sign-up-form form > div {
    margin-bottom: 15px;
    width: 100%; /* Ensure inputs take full width */
    padding: 0 20px; /* Add padding to align inputs to the left */
  }
  
  .sign-up-form form label {
    display: block;
    margin-bottom: 5px;
    color: white;
  }
  
  .sign-up-form form input,
  .sign-up-form form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: rgb(66, 66, 77);
    color: white;
  }
  
  .sign-up-form form button {
    background-color: white;
    color: rgb(46, 46, 55);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .sign-up-form form button:hover {
    background-color: rgb(220, 220, 220);
  }
  

  .button-container {
    text-align: center;
  }
  
  .button-container button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .button-container button:hover {
    background-color: #0056b3;
  }